import { useState } from "react";
import { useEffect } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DataTable from "react-data-table-component";

import { db } from "../firebase-config";
import { collection, where, query, getDocs } from "firebase/firestore";

import Table from "react-bootstrap/Table";
let newLeaguePlayers = [];

export const StatsPlayerBen = (props) => {
  const { division, location } = props;
  let groupPlayers = [];

  const sortMatchP = (rowA, rowB) => {
    const a = Number(rowA.matchWinP.substring(0, rowA.matchWinP.length - 1));
    const b = Number(rowB.matchWinP.substring(0, rowB.matchWinP.length - 1));

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const sortGameP = (rowA, rowB) => {
    const a = Number(rowA.gamesWinP.substring(0, rowA.gamesWinP.length - 1));
    const b = Number(rowB.gamesWinP.substring(0, rowB.gamesWinP.length - 1));

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const sortPtsP = (rowA, rowB) => {
    const a = Number(rowA.ptsWinP.substring(0, rowA.ptsWinP.length - 1));
    const b = Number(rowB.ptsWinP.substring(0, rowB.ptsWinP.length - 1));

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    (async () => {
      const listMatches = query(
        collection(db, "mlpStandingsPlayer"),
        where("event", "in", [
          "2024 MLP1",
          "2024 MLP2",
          // "2024 MLP3",
          "2024 MLP4",
          "2024 MLP5",
          "2024 MLP6",
          "2024 MLP7",
          "2024 MLP8",
          "2024 MLP9",
          // "2024 MLP10",
          // "2024 MLP11",
        ]),
        where("division", "==", division)
      );
      /////////console.log(location);
      /////////console.log(division);

      const querySnapshot = await getDocs(listMatches);
      let teamsList = [];
      querySnapshot.forEach((doc) => {
        teamsList.push({
          ...doc.data(),
          id: doc.id,
        });
      });

      // setTeams(teamsList);
      for (let b = 0; b < teamsList.length; b++) {
        for (let c = 0; c < teamsList[b].teamRank.length; c++) {
          groupPlayers.push(teamsList[b].teamRank[c]);
          // /////////console.log(groupPlayers)
        }
      }

      var holder1 = {};
      var holder2 = {};
      var holder3 = {};
      var holder4 = {};
      var holder5 = {};
      var holder6 = {};
      var holder7 = {};
      var holder8 = {};
      var holder9 = {};

      groupPlayers.forEach(function (d) {
        if (holder1.hasOwnProperty(d.name)) {
          holder1[d.name] = holder1[d.name] + d.dbloss;
        } else {
          holder1[d.name] = d.dbloss;
        }
        if (holder2.hasOwnProperty(d.name)) {
          holder2[d.name] = holder2[d.name] + d.dbwin;
        } else {
          holder2[d.name] = d.dbwin;
        }
        if (holder3.hasOwnProperty(d.name)) {
          holder3[d.name] = holder3[d.name] + d.gamesAgainst;
        } else {
          holder3[d.name] = d.gamesAgainst;
        }
        if (holder4.hasOwnProperty(d.name)) {
          holder4[d.name] = holder4[d.name] + d.gamesFor;
        } else {
          holder4[d.name] = d.gamesFor;
        }
        if (holder5.hasOwnProperty(d.name)) {
          holder5[d.name] = holder5[d.name] + d.matchesAgainst;
        } else {
          holder5[d.name] = d.matchesAgainst;
        }
        if (holder6.hasOwnProperty(d.name)) {
          holder6[d.name] = holder6[d.name] + d.matchesFor;
        } else {
          holder6[d.name] = d.matchesFor;
        }
        if (holder7.hasOwnProperty(d.name)) {
          holder7[d.name] = holder7[d.name] + d.pointsAgainst;
        } else {
          holder7[d.name] = d.pointsAgainst;
        }
        if (holder8.hasOwnProperty(d.name)) {
          holder8[d.name] = holder8[d.name] + d.pointsFor;
        } else {
          holder8[d.name] = d.pointsFor;
        }
        if (holder9.hasOwnProperty(d.name)) {
          holder9[d.name] = holder9[d.name] + d.diff;
        } else {
          holder9[d.name] = d.diff;
        }
      });

      var obj3 = [];

      for (var prop in holder1) {
        obj3.push({
          team: prop,
          dbloss: holder1[prop],
          dbwin: holder2[prop],
          diff: holder9[prop],
          gamesAgainst: holder3[prop],
          gamesFor: holder4[prop],
          matchesAgainst: holder5[prop],
          matchesFor: holder6[prop],
          pointsAgainst: holder7[prop],
          pointsFor: holder8[prop],
          gamesWinP:
            Number(
              (holder4[prop]/
                (holder4[prop]+
                    holder3[prop])) *
                100
            ).toFixed(2).toString() + "%",
          ptsWinP:
            Number(
              (holder8[prop]/
                (holder8[prop] +
                    holder7[prop])) *
                100
            ).toFixed(2).toString()+ "%",
        });
      }

      /////////console.log(obj3);

      obj3.sort((a, b) => {
        return a.gamesFor < b.gamesFor ? -1 : 1;
      });
      obj3.sort((a, b) => {
        return a.pointsFor / a.pointsAgainst < b.pointsFor / b.pointsAgainst
          ? -1
          : 1;
      });

      obj3.sort((a, b) => {
        return a.gamesFor === 0 && a.gamesAgainst === 0 ? 1 : -1;
      });

      let found;
      for (let i = 0; i < obj3.length; i++) {
        if (obj3.team === "-") {
          /////////console.log("found it");
          found = i;
        }
      }

      obj3.forEach((team, index) => {
        team.id = index+1;
      });
      delete teamsList[0].teamRank[found];
      setTeams(obj3);
      // /////////console.log(obj3);
      newLeaguePlayers=obj3;

    })();
  }, []);

  const customStyles = {
    headCells: {
      style: {
        fontSize: "14px", // override the cell padding for head cells
        fontFamily: "SofiaCondensed",
        fontWeight: "bold",
      },
    },
  };

  const columns = [
    {
      name: "RANK",
      selector: (row) => row.id,
      sortable: true,
      compact: true,
      width: "50px",
      style: {
        justifyContent: "center",
        alignContent: "center",
        backgroundColor: "#f0612e",
        color: "white",
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    // {
    //   name: "",
    //   selector: (row) => row.logo,
    //   compact: true,
    //   width: "50px",
    //   style: { justifyContent: "center", alignContent: "center" },
    // },
    {
        name: (
          <Row style={{ paddingLeft: 15 }}>
            <Row>PLAYER</Row>
            <Row style={{ paddingTop: 5 }}> NAME</Row>
          </Row>
        ),
        selector: (row) => row.team,
        sortable: true,
        width: "150px",
        compact: "true",
        style: {
          fontFamily: "SofiaCondensed",
          fontSize: "16px",
          fontWeight: "bold",
        },
      },
    // {
    //   name: (
    //     <Row style={{ paddingLeft: 15 }}>
    //       <Row>TEAM</Row>
    //       <Row style={{ paddingTop: 5 }}> NAME</Row>
    //     </Row>
    //   ),
    //   selector: (row) => row.team,
    //   sortable: true,
    //   width: "150px",
    //   compact: "true",
    //   style: {
    //     fontFamily: "SofiaCondensed",
    //     fontSize: "16px",
    //     fontWeight: "bold",
    //   },
    // },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>GAMES</Row>
          <Row> WON</Row>
        </Col>
      ),
      selector: (row) => row.gamesFor,
      sortable: true,
      width: "60px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>GAMES</Row>
          <Row> LOSS</Row>
        </Col>
      ),
      selector: (row) => row.gamesAgainst,
      sortable: true,
      width: "60px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>GAMES</Row>
          <Row> WON %</Row>
        </Col>
      ),
      selector: (row) => row.gamesWinP,
      sortable: true,
      width: "60px",
      compact: "true",
      sortFunction: sortGameP,
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>PTS</Row>
          <Row> WON</Row>
        </Col>
      ),
      selector: (row) => row.pointsFor,
      sortable: true,
      width: "60px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>PTS</Row>
          <Row> LOSS</Row>
        </Col>
      ),
      selector: (row) => row.pointsAgainst,
      sortable: true,
      width: "60px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>PTS</Row>
          <Row>WON %</Row>
        </Col>
      ),
      selector: (row) => row.ptsWinP,
      sortable: true,
      width: "60px",
      compact: "true",
      sortFunction: sortPtsP,
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
        backgroundColor: "yellow"
      },
    },
  ];

  return (
    // <div style={
    //     {
    //         paddingBottom: "5px",
    //         paddingTop: "5px",

    //         backgroundColor: "white"
    //     }
    // }
    //     className="d-flex justify-content-around">
    //     <Table responsive>
    //         <thead>
    //             <tr style={
    //                 {
    //                     fontSize: "14px",
    //                     fontFamily: "SofiaCondensed"
    //                 }
    //             }>
    //                 <th style={
    //                     { width: "12vw" }
    //                 }>Rank</th>
    //                 <th style={
    //                     { width: "150px" }
    //                 }>Player
    //                     <br></br>Name</th>
    //                 {/* <th style={
    //                     { width: "150px" }
    //                 }>Team
    //                     <br></br>Name</th> */}
    //                 <th style={
    //                     { width: "12vw" }
    //                 }>Games Won</th>
    //                 <th style={
    //                     { width: "12vw" }
    //                 }>Games Loss</th>
    //                 <th style={
    //                     { width: "12vw" }
    //                 }>Games Won %</th>
    //                 <th style={
    //                     { width: "12vw" }
    //                 }>Points Won</th>
    //                 <th style={
    //                     { width: "12vw" }
    //                 }>Points Lost</th>
    //                 <th style={
    //                     { width: "12vw" }
    //                 }>Point Won %</th>
    //             </tr>
    //         </thead>
    //         <tbody> {
    //             teams.map((team, index) => (
    //                 <tr style={
    //                     { fontSize: "11px" }
    //                 }>
    //                     <td style={
    //                         {
    //                             backgroundColor: "#f0612e",
    //                             color: "white",
    //                             fontWeight: "bold",
    //                             fontSize: "14px"
    //                         }
    //                     }>
    //                         {
    //                             index + 1
    //                         } </td>
    //                     {/* <td style={
    //                         {
    //                             fontSize: "14px",
    //                             fontFamily: "Kanit",
    //                             boxShadow: "3px 0 5px -2px #888",
    //                             width: '150px'
    //                         }
    //                     }>
    //                         <Row style={{ width: '150px' }}>
    //                             <Col>{
    //                                 team.name
    //                             }</Col>
    //                         </Row>
    //                     </td> */}
    //                     <td style={
    //                         {
    //                             fontFamily: "SofiaCondensed",
    //                             fontSize: "16px",
    //                             fontWeight: "bold",

    //                         }
    //                     }>
    //                         <Row style={{ width: '150px' }}><Col>
    //                             {
    //                                 team.team
    //                             }</Col>
    //                         </Row>
    //                     </td>
    //                     <td style={
    //                         {
    //                             fontFamily: "SofiaCondensed",
    //                             fontSize: "16px",
    //                             fontWeight: "bold"
    //                         }
    //                     }>{team.gamesFor}</td>
    //                     <td style={
    //                         {
    //                             fontFamily: "SofiaCondensed",
    //                             fontSize: "16px",
    //                             fontWeight: "bold"
    //                         }
    //                     }>{team.gamesAgainst}</td>
    //                     <td style={
    //                         {
    //                             fontFamily: "SofiaCondensed",
    //                             fontSize: "16px",
    //                             fontWeight: "bold"
    //                         }
    //                     }> {
    //                             ((team.gamesFor / (team.gamesAgainst + team.gamesFor)) * 100).toFixed(2)
    //                         }
    //                         %
    //                     </td>
    //                     <td style={
    //                         {
    //                             fontFamily: "SofiaCondensed",
    //                             fontSize: "16px",
    //                             fontWeight: "bold"
    //                         }
    //                     }>{
    //                             team.pointsFor
    //                         }</td>
    //                     <td style={
    //                         {
    //                             fontFamily: "SofiaCondensed",
    //                             fontSize: "16px",
    //                             fontWeight: "bold"
    //                         }
    //                     }>{
    //                             team.pointsAgainst
    //                         }</td>
    //                     <td style={
    //                         {
    //                             fontFamily: "SofiaCondensed",
    //                             fontSize: "16px",
    //                             fontWeight: "bold"
    //                         }
    //                     }> {
    //                             ((team.pointsFor / (team.pointsAgainst + team.pointsFor)) * 100).toFixed(2)
    //                         }
    //                         %
    //                     </td>
    //                 </tr>
    //             ))
    //         } </tbody>
    //     </Table>
    // </div>
    <div
      style={{
        paddingBottom: "5px",
        paddingTop: "5px",
        overflowX: "scroll",
        backgroundColor: "white",
      }}
      className="d-flex justify-content-around"
    >
      <Row>
        <DataTable
          columns={columns}
          data={newLeaguePlayers}
          customStyles={customStyles}
          style={{
            fontSize: "14px",
            fontFamily: "SofiaCondensed",
          }}
        />
      </Row>
    </div>
  );
};
