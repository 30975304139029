import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import { ScheduledMatches } from "./ScheduledMatches";

export const TabsRound = (props) => {
  const { day, division, location, timezone } = props;
  /////////console.log(location);
  /////////console.log(division);
  /////////console.log(day);
  const [currentTab, setCurrentTab] = useState(
    location === "2024 MLP3" ? "6" : "4"
  );
  const tabs = [
    {
      id: 2,
      tabTitle: "DAY 1",
      content: (
        <>
          <ScheduledMatches
            location={location}
            division={division}
            day={day}
            round="DAY 1"
            timezone={timezone}
          />
          <Row
            style={{
              alignContent: "center",
              justifyContent: "center",
              color: "white",
              backgroundColor: "#0a2137",
              height: "20px",
              padding: "10px",
            }}
          ></Row>
        </>
      ),
    },
    {
      id: 3,
      tabTitle: "DAY 2",
      content: (
        <>
          <ScheduledMatches
            location={location}
            division={division}
            round="DAY 2"
            timezone={timezone}
          />
          <Row
            style={{
              alignContent: "center",
              justifyContent: "center",
              color: "white",
              backgroundColor: "#0a2137",
              height: "20px",
              padding: "10px",
            }}
          ></Row>
        </>
      ),
    },

    {
      id: 4,
      tabTitle: "DAY 3",
      content: (
        <>
          <ScheduledMatches
            location={location}
            division={division}
            round="DAY 3"
            timezone={timezone}
          />
          <Row
            style={{
              alignContent: "center",
              justifyContent: "center",
              color: "white",
              backgroundColor: "#0a2137",
              height: "20px",
              padding: "10px",
            }}
          ></Row>
        </>
      ),
    },
    {
      id: 5,
      tabTitle: "DAY 4",
      content: (
        <>
          <ScheduledMatches
            location={location}
            division={division}
            round="DAY 4"
            timezone={timezone}
          />
          <Row
            style={{
              alignContent: "center",
              justifyContent: "center",
              color: "white",
              backgroundColor: "#0a2137",
              height: "20px",
              padding: "10px",
            }}
          ></Row>
        </>
      ),
    },
  ];

  const tabs3 = [
    {
      id: 2,
      tabTitle: "DAY 1",
      content: (
        <>
          <ScheduledMatches
            location={location}
            division={division}
            day={day}
            round="DAY 1"
            timezone={timezone}
          />
          <Row
            style={{
              alignContent: "center",
              justifyContent: "center",
              color: "white",
              backgroundColor: "#0a2137",
              height: "20px",
              padding: "10px",
            }}
          ></Row>
        </>
      ),
    },
    {
      id: 3,
      tabTitle: "DAY 2",
      content: (
        <>
          <ScheduledMatches
            location={location}
            division={division}
            round="DAY 2"
            timezone={timezone}
          />
          <Row
            style={{
              alignContent: "center",
              justifyContent: "center",
              color: "white",
              backgroundColor: "#0a2137",
              height: "20px",
              padding: "10px",
            }}
          ></Row>
        </>
      ),
    },

    {
      id: 4,
      tabTitle: "DAY 3",
      content: (
        <>
          <ScheduledMatches
            location={location}
            division={division}
            round="DAY 3"
            timezone={timezone}
          />
          <Row
            style={{
              alignContent: "center",
              justifyContent: "center",
              color: "white",
              backgroundColor: "#0a2137",
              height: "20px",
              padding: "10px",
            }}
          ></Row>
        </>
      ),
    },
  ];
  const tabs5 = [
    {
      id: 2,
      tabTitle: "DAY 1",
      content: (
        <>
          <ScheduledMatches
            location={location}
            division={division}
            day={day}
            round="DAY 1"
            timezone={timezone}
          />
          <Row
            style={{
              alignContent: "center",
              justifyContent: "center",
              color: "white",
              backgroundColor: "#0a2137",
              height: "20px",
              padding: "10px",
            }}
          ></Row>
        </>
      ),
    },
    {
      id: 3,
      tabTitle: "DAY 2",
      content: (
        <>
          <ScheduledMatches
            location={location}
            division={division}
            round="DAY 2"
            timezone={timezone}
          />
          <Row
            style={{
              alignContent: "center",
              justifyContent: "center",
              color: "white",
              backgroundColor: "#0a2137",
              height: "20px",
              padding: "10px",
            }}
          ></Row>
        </>
      ),
    },

    {
      id: 4,
      tabTitle: "DAY 3",
      content: (
        <>
          <ScheduledMatches
            location={location}
            division={division}
            round="DAY 3"
            timezone={timezone}
          />
          <Row
            style={{
              alignContent: "center",
              justifyContent: "center",
              color: "white",
              backgroundColor: "#0a2137",
              height: "20px",
              padding: "10px",
            }}
          ></Row>
        </>
      ),
    },
    {
      id: 5,
      tabTitle: "DAY 4",
      content: (
        <>
          <ScheduledMatches
            location={location}
            division={division}
            round="DAY 4"
            timezone={timezone}
          />
          <Row
            style={{
              alignContent: "center",
              justifyContent: "center",
              color: "white",
              backgroundColor: "#0a2137",
              height: "20px",
              padding: "10px",
            }}
          ></Row>
        </>
      ),
    },
    {
      id: 6,
      tabTitle: "DAY 5",
      content: (
        <>
          <ScheduledMatches
            location={location}
            division={division}
            round="DAY 5"
            timezone={timezone}
          />
          <Row
            style={{
              alignContent: "center",
              justifyContent: "center",
              color: "white",
              backgroundColor: "#0a2137",
              height: "20px",
              padding: "10px",
            }}
          ></Row>
        </>
      ),
    },
  ];

  let tabsSelect =
    location === "2024 MLP3" ? tabs5 : 
    location === "2024 MLP5" ? tabs3 : 
    location === "2024 MLP8" ? tabs3 : 
    location === "2024 MLP10" ? tabs3 : 

    tabs;

  const handleTabClick = (e) => {
    setCurrentTab(e.target.id);
  };

  return (
    <div style={{ paddingTop: "-20px" }}>
      <style type="text/css">
        {`
    .matchListHeader{
      background-color: #1b3448;
      border-radius: 25px;
      padding: 5px;
    }
    .matchListHeader2{
      background-color: #ef612d;
      border-radius: 25px;
      padding: 5px;
    }
    .matchListTitle{
      color: white;
    }

    .roundBtn{
      background-color: #0a2137;
      color: #ef612d;

      cursor: pointer;
      transition: all 0.25s ease-out;
      margin: 1px;
    }

    .roundBtn:hover{
      background-color: #0a2137;
      color: #ef612d;

      cursor: pointer;
      transition: all 0.25s ease-out;
      margin: 1px;
    }

    .roundBtn:focus{
      background-color: #0a2137;
      color: #ef612d;

      cursor: pointer;
      transition: all 0.25s ease-out;
      margin: 1px;
    }

    `}{" "}
      </style>
      <div
        style={{
          maxWidth: "1200px",
          alignContent: "center",
          justifyContent: "center",
          // marginTop: "10px",
          backgroundColor: "#0a2137",
        }}
      >
        <div
          style={{ backgroundColor: "#0a2137" }}
          className="tabs d-flex justify-content-around font-kanit"
        >
          {tabsSelect.map((tab, i) => (
            <>
              {" "}
              <div
                style={{
                  backgroundColor: "#0a2137",
                  width: "2px",
                }}
              ></div>
              <Button
                type="button"
                style={{
                  width: "50vw",
                  height: "40px",
                  margin: "1px",
                  fontSize: "12px",
                  fontWeight: "bold",
                  borderRadius: "2px",
                  borderColor: "#0a2137",
                }}
                key={i}
                className={"btn btn-lg roundBtn"}
                id={tab.id}
                active={currentTab !== `${tab.id}`}
                onClick={handleTabClick}
                variant="info"
                size="sm"
              >
                <a id={tab.id} onClick={handleTabClick}>
                  {tab.tabTitle ? tab.tabTitle : "ALL"}{" "}
                </a>
              </Button>
            </>
          ))}{" "}
        </div>
        <div className="content">
          {tabsSelect.map((tab, i) => (
            <>
              <div key={i}>
                {currentTab === `${tab.id}` && (
                  <div>
                    <p className="title">{tab.title}</p>
                    <div>{tab.content}</div>
                  </div>
                )}{" "}
              </div>
            </>
          ))}{" "}
        </div>
      </div>
    </div>
  );
};
