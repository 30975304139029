import { db } from "../firebase-config";
import { collection, where, query, getDocs } from "firebase/firestore";
import { useState } from "react";
import { useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export const Viewers = (props) => {
    const [viewers, setViewers] = useState([]);

    useEffect(() => {
        (async () => {
          const viewerList = query(
            collection(db, "mlpDivices"),
          );
          const querySnapshot = await getDocs(viewerList);
          querySnapshot.forEach((doc) => {
            let viewersArray = [];
            viewersArray.push({
              ...doc.data(),
              id: doc.id,
            });
            setViewers(viewersArray)
          });
          /////////console.log(viewers)
        })();
      }, []);
  return (
    <p>
        {JSON.stringify(viewers[0])} live
    </p>
  );
};