import { useState } from "react";
import { useEffect } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";

import DataTable from "react-data-table-component";

import { db } from "../firebase-config.js";
import { collection, where, query, getDocs } from "firebase/firestore";

import Table from "react-bootstrap/Table";

let newTeams = [];

export const EventStatsTeamBen = (props) => {
  const { division, location, round } = props;

  const listTeams = collection(db, "mlpStandingsTeam");
  let [teams, setTeams] = useState([]);
  let [mlpTeams, setmlpTeams] = useState([]);
  let sortId = "";

  const sortMatchP = (rowA, rowB) => {
    const a = Number(rowA.matchWinP.substring(0, rowA.matchWinP.length - 1));
    const b = Number(rowB.matchWinP.substring(0, rowB.matchWinP.length - 1));
  
    if (a > b) {
      return 1;
    }
  
    if (b > a) {
      return -1;
    }
  
    return 0;
  };

  const sortGameP = (rowA, rowB) => {
    const a = Number(rowA.gamesWinP.substring(0, rowA.gamesWinP.length - 1));
    const b = Number(rowB.gamesWinP.substring(0, rowB.gamesWinP.length - 1));
  
    if (a > b) {
      return 1;
    }
  
    if (b > a) {
      return -1;
    }
  
    return 0;
  };

  const sortPtsP = (rowA, rowB) => {
    const a = Number(rowA.ptsWinP.substring(0, rowA.ptsWinP.length - 1));
    const b = Number(rowB.ptsWinP.substring(0, rowB.ptsWinP.length - 1));
  
  
    if (a > b) {
      return 1;
    }
  
    if (b > a) {
      return -1;
    }
  
    return 0;
  };

  function firstFunction(by, _callback) {
    let newRank = teams;
    /////////console.log(newRank);
    newRank.click = by;
    newRank[0].teamRank.sort((a, b) => {
      if (sortId === "REG LOSS") {
        return a.matchesAgainst - a.dbloss < b.matchesAgainst - b.dbloss
          ? 1
          : -1;
      }
      if (sortId === "REG WIN") {
        return a.matchesFor - a.dbwin < b.matchesFor - b.dbwin ? 1 : -1;
      }
      if (sortId === "DB WIN") {
        return a.dbwin < b.dbwin ? 1 : -1;
      }
      if (sortId === "DB LOSS") {
        return a.dbloss < b.dbloss ? 1 : -1;
      }
    });
    _callback(newRank);
  }

  function sortStandings(by) {
    /////////console.log(by);
    sortId = by;
    // call first function and pass in a callback function which
    // first function runs when it has completed
    firstFunction(by, function (callbacked) {
      setTeams(callbacked);
      setmlpTeams(callbacked);
    });
  }

  let roundSelect = "";
  let roundSelector = "";

  if (round === "GROUP A") {
    roundSelect = "GROUP A";
  }
  if (round === "GROUP B") {
    roundSelect = "GROUP B";
  }
  if (round === "GROUP C") {
    roundSelect = "GROUP C";
  }
  if (!round.includes("GROUP")) {
    roundSelect = [
      "QUARTERFINALS 1",
      "QUARTERFINALS 2",
      "QUARTERFINALS 3",
      "QUARTERFINALS 4",
      "SIMIFINALS 1",
      "SIMIFINALS 2",
      "FINALS",
    ];
  }

  if (round === "GROUP A") {
    roundSelector = "==";
  }
  if (round === "GROUP B") {
    roundSelector = "==";
  }
  if (round === "GROUP C") {
    roundSelector = "==";
  }
  if (!round.includes("GROUP")) {
    roundSelector = "in";
  }

  useEffect(() => {
    (async () => {
      const listMatches = query(
        collection(db, "mlpStandingsTeam"),
        where("division", "==", division),
        where("event", "==", location)
      );

      const listTeams = query(
        collection(db, "mlpteams"),
        where("divisionID", "==", division),
        where("eventID", "==", location)
      );

      const querySnapshot = await getDocs(listMatches);
      let teamsList = [];
      querySnapshot.forEach((doc) => {
        teamsList.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      setTeams(teamsList);

      const queryTeamsSnapshot = await getDocs(listTeams);
      let mlpTeamsList = [];
      queryTeamsSnapshot.forEach((doc) => {
        mlpTeamsList.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      setmlpTeams(mlpTeamsList);
      // /////////console.log(mlpTeams)

      // /////////console.log(teamsList);
      for (let i = 0; i < mlpTeamsList.length; i++) {
        // /////////console.log(mlpTeamsList[i].teamName)
        for (let r = 0; r < teamsList[0].teamRank.length; r++) {
          // /////////console.log(teamsList[0].teamRank[r].team)
          if (mlpTeamsList[i].teamName === teamsList[0].teamRank[r].team) {
            // /////////console.log(teamsList[0].teamRank[r].team)
            // /////////console.log(mlpTeamsList[i].teamName)
            teamsList[0].teamRank[r]["teamDUPR"] = mlpTeamsList[i].teamDUPR;
            // /////////console.log(teamsList[0].teamRank[r].teamDUPR)
            let logo1 = "5s";
            switch (mlpTeamsList[i].teamName) {
              case "ATX Pickleballers":
                logo1 = "atx";
                break;
              case "Las Vegas Night Owls":
                logo1 = "lv";
                break;
              case "California Black Bears":
                logo1 = "blqk";
                break;
              case "Florida Smash":
                logo1 = "smash";
                break;
              case "LA Mad Drops":
                logo1 = "maddrops";
                break;
              case "Milwaukee Mashers":
                logo1 = "mashers";
                break;
              case "New Jersey 5s":
                logo1 = "5s";
                break;

              case "Frisco Pandas":
                logo1 = "clean";
                break;
              case "NY Hustlers":
                logo1 = "hustlers";
                break;
              case "Seattle Pioneers":
                logo1 = "pioneers";
                break;
              case "Carolina Pickleball Club":
                logo1 = "pioneers";
                break;
              case "SoCal Hard Eights":
                logo1 = "eights";
                break;
              case "STL Shock":
                logo1 = "shock";
                break;
              case "AZ Drive":
                logo1 = "drive";
                break;
              case "ATL Bouncers":
                logo1 = "bouncers";
                break;
              case "Bay Area Breakers":
                logo1 = "breakers";
                break;
              case "Brooklyn Aces":
                logo1 = "aces";
                break;
              case "Chicago Slice":
                logo1 = "slice";
                break;
              case "Columbus Sliders":
                logo1 = "columbus";
                break;
              case "D.C. Pickleball Team":
                logo1 = "dc";
                break;
              case "Dallas Flash Pickleball":
                logo1 = "dallas";
                break;
              case "Miami Pickleball Club":
                logo1 = "miami";
                break;
              case "Orlando Squeeze":
                logo1 = "orlando";
                break;
              case "Texas Ranchers":
                logo1 = "ranchers";
                break;
              case "Utah Black Diamonds":
                logo1 = "diamonds";
                break;
              default:
              // code block
            }
            teamsList[0].teamRank[r]["logo"] = logo1;
          }
        }
      }
      // /////////console.log(teamsList[0].teamRank[i].team);
      // setTeams(teamsList);

      for (let i = 0; i < teamsList[0].teamRank.length; i++) {
        // /////////console.log(teamsList[0].teamRank[i].team);
        if (teamsList[0].teamRank[i].team === null) {
          teamsList[0].teamRank.splice(i, 1);
        }
        if (teamsList[0].teamRank[i].team === "") {
          teamsList[0].teamRank.splice(i, 1);
        }
        if (teamsList[0].teamRank[i].team === " ") {
          teamsList[0].teamRank.splice(i, 1);
        }
        if (teamsList[0].teamRank[i].team === "BYE") {
          teamsList[0].teamRank.splice(i, 1);
        }
        if (teamsList[0].teamRank[i].team === "-") {
          teamsList[0].teamRank.splice(i, 1);
        }
      }

      teamsList[0].teamRank.sort((a, b) => {
        return a.team < b.team ? -1 : 1;
      });

      teamsList[0].teamRank.sort((a, b) => {
        return a.matchesFor + a.matchesAgainst < b.matchesFor + b.matchesAgainst
          ? 1
          : -1;
      });

      teamsList[0].teamRank.sort((a, b) => {
        return a.diff < b.diff ? 1 : -1;
      });

      /////////console.log(teamsList[0]);

      teamsList[0].teamRank.forEach((team, index) => {});
      setTeams(teamsList);
      // /////////console.log(teamsList);
      let i = 0;
      newTeams=[];

      while (i < teamsList[0].teamRank.length) {
        let newArray = {};
        newArray = {
          id: i + 1,
          dbloss: teamsList[0].teamRank[i].dbloss,
          dbwin: teamsList[0].teamRank[i].dbwin,
          diff: teamsList[0].teamRank[i].diff,
          gamesAgainst: teamsList[0].teamRank[i].gamesAgainst,
          gamesFor: teamsList[0].teamRank[i].gamesFor,
          logo: (
            <Image
              style={{ width: "30px" }}
              src={require("./images/teams/png" +
                teamsList[0].teamRank[i].logo +
                ".png")}
            />
          ),
          matchesAgainst: teamsList[0].teamRank[i].matchesAgainst,
          matchesFor: teamsList[0].teamRank[i].matchesFor,
          pointsAgainst: teamsList[0].teamRank[i].pointsAgainst,
          pointsFor: teamsList[0].teamRank[i].pointsFor,
          roundID: teamsList[0].teamRank[i].roundID,
          team: teamsList[0].teamRank[i].team,
          regWin:
            teamsList[0].teamRank[i].matchesFor -
            teamsList[0].teamRank[i].dbwin,
          regLoss:
            teamsList[0].teamRank[i].matchesAgainst -
            teamsList[0].teamRank[i].dbloss,
          matches:
            teamsList[0].teamRank[i].matchesFor +
            teamsList[0].teamRank[i].matchesAgainst,
          ptsMatch: Number(
            teamsList[0].teamRank[i].diff /
            (teamsList[0].teamRank[i].matchesFor +
              teamsList[0].teamRank[i].matchesAgainst)
          ).toFixed(2),
          matchWinP: Number(
            (teamsList[0].teamRank[i].matchesFor /
              (teamsList[0].teamRank[i].matchesFor +
                teamsList[0].teamRank[i].matchesAgainst)) *
            100
          ).toFixed(2)+"%",
          gamesWinP: Number(
            (teamsList[0].teamRank[i].gamesFor /
              (teamsList[0].teamRank[i].gamesFor +
                teamsList[0].teamRank[i].gamesAgainst)) *
            100
          ).toFixed(2)+"%",
          ptsWinP: Number(
            (teamsList[0].teamRank[i].pointsFor /
              (teamsList[0].teamRank[i].pointsFor +
                teamsList[0].teamRank[i].pointsAgainst)) *
            100
          ).toFixed(2)+"%",
        };
        /////////console.log(newArray);
        newTeams.push(newArray);
        i++;
      }
      // newTeams = teamsList[0].teamRank
    })();
  }, []);

  const customStyles = {
    headCells: {
      style: {
        fontSize: "14px", // override the cell padding for head cells
        fontFamily: "SofiaCondensed",
        fontWeight: "bold",
      },
    },
  };

  const columns = [
    {
      name: "RANK",
      selector: (row) => row.id,
      sortable: true,
      compact: true,
      width: "50px",
      style: {
        justifyContent: "center",
        alignContent: "center",
        backgroundColor: "#f0612e",
        color: "white",
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: "",
      selector: (row) => row.logo,
      compact: true,
      width: "50px",
      style: { justifyContent: "center", alignContent: "center" },
    },
    {
      name: (
        <Row style={{ paddingLeft: 15 }}>
          <Row>TEAM</Row>
          <Row style={{ paddingTop: 5 }}> NAME</Row>
        </Row>
      ),
      selector: (row) => row.team,
      sortable: true,
      width: "150px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>REG</Row>
          <Row > WIN</Row>
        </Col>
      ),
      selector: (row) => row.regWin,
      sortable: true,
      width: "60px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>REG</Row>
          <Row > LOSS</Row>
        </Col>
      ),
      selector: (row) => row.regLoss,
      sortable: true,
      width: "60px",
      compact: "true",

      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>DB</Row>
          <Row > WIN</Row>
        </Col>
      ),
      selector: (row) => row.dbwin,
      sortable: true,
      width: "60px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>DB</Row>
          <Row >LOSS</Row>
        </Col>
      ),
      selector: (row) => row.dbloss,
      sortable: true,
      width: "60px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: "POINTS",
      selector: (row) => row.diff,
      sortable: true,
      width: "60px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
        backgroundColor: "yellow"
      },
    },
    {
      name: "MATCHES",
      selector: (row) => row.matches,
      sortable: true,
      width: "80px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>PTS/</Row>
          <Row > MATCH</Row>
        </Col>
      ),
      selector: (row) => row.ptsMatch,
      sortable: true,
      width: "60px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>MATCH</Row>
          <Row >WIN %</Row>
        </Col>
      ),
      selector: (row) => row.matchWinP,
      sortable: true,
      width: "60px",
      compact: "true",
      sortFunction: sortMatchP,
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: <Col style={{ paddingLeft: 15 }}>
      <Row>GAMES</Row>
      <Row > WON</Row>
    </Col>,
      selector: (row) => row.gamesFor,
      sortable: true,
      width: "60px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: <Col style={{ paddingLeft: 15 }}>
      <Row>GAMES</Row>
      <Row > LOSS</Row>
    </Col>,
      selector: (row) => row.gamesAgainst,
      sortable: true,
      width: "60px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: <Col style={{ paddingLeft: 15 }}>
      <Row>GAMES</Row>
      <Row > WON %</Row>
    </Col>,
      selector: (row) => row.gamesWinP,
      sortable: true,
      width: "60px",
      compact: "true",
      sortFunction: sortGameP,
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: <Col style={{ paddingLeft: 15 }}>
      <Row>PTS</Row>
      <Row > WON</Row>
    </Col>,
      selector: (row) => row.pointsFor,
      sortable: true,
      width: "60px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: <Col style={{ paddingLeft: 15 }}>
      <Row>PTS</Row>
      <Row > LOSS</Row>
    </Col>,
      selector: (row) => row.pointsAgainst,
      sortable: true,
      width: "60px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: <Col style={{ paddingLeft: 15 }}>
      <Row>PTS</Row>
      <Row >WON %</Row>
    </Col>,
      selector: (row) => row.ptsWinP,
      sortable: true,
      width: "60px",
      compact: "true",
      sortFunction: sortPtsP,
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
  ];

  /////////console.log(newTeams);

  return (
    <>
      <Card
        style={{
          marginBottom: 5,
          color: "white",
          backgroundColor: "#FFFFFF33",
        }}
      >
        <Card.Header>Season Standings</Card.Header>
        <Card.Body>
          <blockquote className="blockquote mb-0">
            <p style={{ fontSize: "14px" }}>
              Season standings reflect regular season match results:
            </p>
            <ul style={{ fontSize: "12px" }}>
              <li>3 Points: Regulation win</li>
              <li>2 Points: DreamBreaker win</li>
              <li>1 Point: DreamBreaker loss</li>
              <li>0 Points: Regulation loss</li>
            </ul>
          </blockquote>
        </Card.Body>
      </Card>
      <div
        style={{
          paddingBottom: "5px",
          paddingTop: "5px",
          zoom: "100%",
          backgroundColor: "white",
          overflowX: "scroll"
        }}
        className="d-flex justify-content-around"
      >
        <Row>
        <DataTable
          columns={columns}
          data={newTeams}
          customStyles={customStyles}
          style={{
            fontSize: "14px",
            fontFamily: "SofiaCondensed",
          }}
        />
      </Row>
      </div>
      
    </>
  );
};
