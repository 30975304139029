import { useState } from "react";
import { useEffect } from "react";

import Image from "react-bootstrap/Image";
import { db } from "../firebase-config";
import { collection, where, query, getDocs } from "firebase/firestore";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import DataTable from "react-data-table-component";

let newPlayers = [];

export const EventStatsPlayerBen = (props) => {
  const { division, location } = props;

  const [teams, setTeams] = useState([]);

  const sortMatchP = (rowA, rowB) => {
    const a = Number(rowA.matchWinP.substring(0, rowA.matchWinP.length - 1));
    const b = Number(rowB.matchWinP.substring(0, rowB.matchWinP.length - 1));

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const sortGameP = (rowA, rowB) => {
    const a = Number(rowA.gamesWinP.substring(0, rowA.gamesWinP.length - 1));
    const b = Number(rowB.gamesWinP.substring(0, rowB.gamesWinP.length - 1));

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const sortPtsP = (rowA, rowB) => {
    const a = Number(rowA.ptsWinP.substring(0, rowA.ptsWinP.length - 1));
    const b = Number(rowB.ptsWinP.substring(0, rowB.ptsWinP.length - 1));

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  
  useEffect(() => {
    (async () => {
      const listMatches = query(
        collection(db, "mlpStandingsPlayer"),
        where("event", "==", location),
        where("division", "==", division)
      );
      /////////console.log(location);
      /////////console.log(division);
      const querySnapshot = await getDocs(listMatches);
      let teamsList = [];
      querySnapshot.forEach((doc) => {
        teamsList.push({
          ...doc.data(),
          id: doc.id,
        });
      });

      setTeams(teamsList);

      teamsList[0].teamRank.sort((a, b) => {
        return a.gamesFor < b.gamesFor ? -1 : 1;
      });
      teamsList[0].teamRank.sort((a, b) => {
        return a.pointsFor / a.pointsAgainst < b.pointsFor / b.pointsAgainst
          ? -1
          : 1;
      });

      teamsList[0].teamRank.sort((a, b) => {
        return a.gamesFor === 0 && a.gamesAgainst === 0 ? 1 : -1;
      });

      let found;
      for (let i = 0; i < teamsList[0].teamRank.length; i++) {
        if (teamsList[0].teamRank[i].name === "-") {
          teamsList[0].teamRank[i].name = " "
          // /////////console.log("found it");
        }
      }
      
      setTeams(teamsList);
      // /////////console.log(teamsList);

      let i = 0;
      newPlayers = [];

      while (i < teamsList[0].teamRank.length) {
        let newPlayerArray = {};
        let logo1 = "5s";
        switch (teamsList[0].teamRank[i].team) {
          case "ATX Pickleballers":
            logo1 = "atx";
            break;
          case "Las Vegas Night Owls":
            logo1 = "lv";
            break;
          case "California Black Bears":
            logo1 = "blqk";
            break;
          case "Florida Smash":
            logo1 = "smash";
            break;
          case "LA Mad Drops":
            logo1 = "maddrops";
            break;
          case "Milwaukee Mashers":
            logo1 = "mashers";
            break;
          case "New Jersey 5s":
            logo1 = "5s";
            break;

          case "Frisco Pandas":
            logo1 = "clean";
            break;
          case "NY Hustlers":
            logo1 = "hustlers";
            break;
          case "Seattle Pioneers":
            logo1 = "pioneers";
            break;
          case "Carolina Pickleball Club":
            logo1 = "pioneers";
            break;
          case "SoCal Hard Eights":
            logo1 = "eights";
            break;
          case "STL Shock":
            logo1 = "shock";
            break;
          case "AZ Drive":
            logo1 = "drive";
            break;
          case "ATL Bouncers":
            logo1 = "bouncers";
            break;
          case "Bay Area Breakers":
            logo1 = "breakers";
            break;
          case "Brooklyn Aces":
            logo1 = "aces";
            break;
          case "Chicago Slice":
            logo1 = "slice";
            break;
          case "Columbus Sliders":
            logo1 = "columbus";
            break;
          case "D.C. Pickleball Team":
            logo1 = "dc";
            break;
          case "Dallas Flash Pickleball":
            logo1 = "dallas";
            break;
          case "Miami Pickleball Club":
            logo1 = "miami";
            break;
          case "Orlando Squeeze":
            logo1 = "orlando";
            break;
          case "Texas Ranchers":
            logo1 = "ranchers";
            break;
          case "Utah Black Diamonds":
            logo1 = "diamonds";
            break;
          default:
          // code block
        }
        
        newPlayerArray = {
          id: i + 1,
          diff: teamsList[0].teamRank[i].diff,
          gamesAgainst: teamsList[0].teamRank[i].gamesAgainst,
          gamesFor: teamsList[0].teamRank[i].gamesFor,
          matchesAgainst: teamsList[0].teamRank[i].matchesAgainst,
          matchesFor: teamsList[0].teamRank[i].matchesFor,
          pointsAgainst: teamsList[0].teamRank[i].pointsAgainst,
          pointsFor: teamsList[0].teamRank[i].pointsFor,
          team: teamsList[0].teamRank[i].team,
          name: teamsList[0].teamRank[i].name,
          logo: (
            <Image
              style={{ width: "30px" }}
              src={require("./images/teams/png" +
                logo1 +
                ".png")}
            />
          ),
          matches:
            teamsList[0].teamRank[i].matchesFor +
            teamsList[0].teamRank[i].matchesAgainst,
          gamesWinP:
            Number(
              (teamsList[0].teamRank[i].gamesFor /
                (teamsList[0].teamRank[i].gamesFor +
                  teamsList[0].teamRank[i].gamesAgainst)) *
                100
            ).toFixed(2) + "%",
          ptsWinP:
            Number(
              (teamsList[0].teamRank[i].pointsFor /
                (teamsList[0].teamRank[i].pointsFor +
                  teamsList[0].teamRank[i].pointsAgainst)) *
                100
            ).toFixed(2) + "%",
        };
        /////////console.log(newPlayerArray);
        if (newPlayerArray.name===' '){

        }else{
        newPlayers.push(newPlayerArray);
        }
        i++;
        
      }
    })();
  }, []);

  const customStyles = {
    headCells: {
      style: {
        fontSize: "14px", // override the cell padding for head cells
        fontFamily: "SofiaCondensed",
        fontWeight: "bold",
      },
    },
  };

  const columns = [
    {
      name: "RANK",
      selector: (row) => row.id,
      sortable: true,
      compact: true,
      width: "50px",
      style: {
        justifyContent: "center",
        alignContent: "center",
        backgroundColor: "#f0612e",
        color: "white",
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: "",
      selector: (row) => row.logo,
      compact: true,
      width: "50px",
      style: { justifyContent: "center", alignContent: "center" },
    },
    {
        name: (
          <Row style={{ paddingLeft: 15 }}>
            <Row>PLAYER</Row>
            <Row style={{ paddingTop: 5 }}> NAME</Row>
          </Row>
        ),
        selector: (row) => row.name,
        sortable: true,
        width: "150px",
        compact: "true",
        style: {
          fontFamily: "SofiaCondensed",
          fontSize: "16px",
          fontWeight: "bold",
        },
      },
    // {
    //   name: (
    //     <Row style={{ paddingLeft: 15 }}>
    //       <Row>TEAM</Row>
    //       <Row style={{ paddingTop: 5 }}> NAME</Row>
    //     </Row>
    //   ),
    //   selector: (row) => row.team,
    //   sortable: true,
    //   width: "150px",
    //   compact: "true",
    //   style: {
    //     fontFamily: "SofiaCondensed",
    //     fontSize: "16px",
    //     fontWeight: "bold",
    //   },
    // },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>GAMES</Row>
          <Row> WON</Row>
        </Col>
      ),
      selector: (row) => row.gamesFor,
      sortable: true,
      width: "60px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>GAMES</Row>
          <Row> LOSS</Row>
        </Col>
      ),
      selector: (row) => row.gamesAgainst,
      sortable: true,
      width: "60px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>GAMES</Row>
          <Row> WON %</Row>
        </Col>
      ),
      selector: (row) => row.gamesWinP,
      sortable: true,
      width: "60px",
      compact: "true",
      sortFunction: sortGameP,
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>PTS</Row>
          <Row> WON</Row>
        </Col>
      ),
      selector: (row) => row.pointsFor,
      sortable: true,
      width: "60px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>PTS</Row>
          <Row> LOSS</Row>
        </Col>
      ),
      selector: (row) => row.pointsAgainst,
      sortable: true,
      width: "60px",
      compact: "true",
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
      },
    },
    {
      name: (
        <Col style={{ paddingLeft: 15 }}>
          <Row>PTS</Row>
          <Row>WON %</Row>
        </Col>
      ),
      selector: (row) => row.ptsWinP,
      sortable: true,
      width: "60px",
      compact: "true",
      sortFunction: sortPtsP,
      style: {
        fontFamily: "SofiaCondensed",
        fontSize: "16px",
        fontWeight: "bold",
        backgroundColor: "yellow"
      },
    },
  ];

  return (
    <div
      style={{
        paddingBottom: "5px",
        paddingTop: "5px",
        overflowX: "scroll",
        backgroundColor: "white",
      }}
      className="d-flex justify-content-around"
    >
      {/* <Table responsive>
                <thead>
                    <tr style={
                        {
                            fontSize: "14px",
                            fontFamily: "SofiaCondensed"
                        }
                    }>
                        <th style={
                            { width: "12vw" }
                        }>Rank</th>
                        <th style={
                            { width: "150px" }
                        }>Player
                            <br></br>Name</th>
                        <th style={
                            { width: "150px" }
                        }>Team
                            <br></br>Name</th>
                        <th style={
                            { width: "12vw" }
                        }>Games Won</th>
                        <th style={
                            { width: "12vw" }
                        }>Games Loss</th>
                        <th style={
                            { width: "12vw" }
                        }>Games Won %</th>
                        <th style={
                            { width: "12vw" }
                        }>Points Won</th>
                        <th style={
                            { width: "12vw" }
                        }>Points Lost</th>
                        <th style={
                            { width: "12vw" }
                        }>Point Won %</th>
                    </tr>
                </thead>
                <tbody> {
                    teams.map((team) => team.teamRank.map((team, index) => (
                        <tr style={
                            { fontSize: "11px" }
                        }>
                            <td style={
                                {
                                    backgroundColor: "#f0612e",
                                    color: "white",
                                    fontWeight: "bold",
                                    fontSize: "14px"
                                }
                            }>
                                {
                                    index + 1
                                } </td>
                            <td style={
                                {
                                    fontSize: "14px",
                                    fontFamily: "Kanit",
                                    boxShadow: "3px 0 5px -2px #888",
                                    width: '150px'
                                }
                            }>
                                <Row style={{ width: '150px' }}>
                                    <Col>{
                                        team.name
                                    }</Col>
                                </Row>
                            </td>
                            <td style={
                                {
                                    fontFamily: "SofiaCondensed",
                                    fontSize: "16px",
                                    fontWeight: "bold",

                                }
                            }>
                                <Row style={{ width: '150px' }}><Col>
                                    {
                                        team.team
                                    }</Col>
                                </Row>
                            </td>
                            <td style={
                                {
                                    fontFamily: "SofiaCondensed",
                                    fontSize: "16px",
                                    fontWeight: "bold"
                                }
                            }>{team.gamesFor}</td>
                            <td style={
                                {
                                    fontFamily: "SofiaCondensed",
                                    fontSize: "16px",
                                    fontWeight: "bold"
                                }
                            }>{team.gamesAgainst}</td>
                            <td style={
                                {
                                    fontFamily: "SofiaCondensed",
                                    fontSize: "16px",
                                    fontWeight: "bold"
                                }
                            }> {
                                    ((team.gamesFor / (team.gamesAgainst + team.gamesFor)) * 100).toFixed(2)
                                }
                                %
                            </td>
                            <td style={
                                {
                                    fontFamily: "SofiaCondensed",
                                    fontSize: "16px",
                                    fontWeight: "bold"
                                }
                            }>{
                                    team.pointsFor
                                }</td>
                            <td style={
                                {
                                    fontFamily: "SofiaCondensed",
                                    fontSize: "16px",
                                    fontWeight: "bold"
                                }
                            }>{
                                    team.pointsAgainst
                                }</td>
                            <td style={
                                {
                                    fontFamily: "SofiaCondensed",
                                    fontSize: "16px",
                                    fontWeight: "bold"
                                }
                            }> {
                                    ((team.pointsFor / (team.pointsAgainst + team.pointsFor)) * 100).toFixed(2)
                                }
                                %
                            </td>
                        </tr>
                    )))
                } </tbody>
            </Table> */}

<Row>
        <DataTable
          columns={columns}
          data={newPlayers}
          customStyles={customStyles}
          style={{
            fontSize: "14px",
            fontFamily: "SofiaCondensed",
          }}
        />
      </Row>
    </div>
  );
};
