import { useState } from "react";
import { useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ScheduledMatchLayout } from "./ScheduledMatchLayout";
import { db } from "../firebase-config";
import { collection, where, query, getDocs } from "firebase/firestore";

export const ScheduledMatches = (props) => {
  const { day, division, location, round, timezone } = props;
  /////////console.log(location);
  /////////console.log(round);
  const [matches, setMatches] = useState([]);

  let roundSelect = "";
  let roundSelector = "";

  if (round === "DAY 1") {
    roundSelect = "DAY 1";
  }
  if (round === "DAY 2") {
    roundSelect = "DAY 2";
  }
  if (round === "DAY 3") {
    roundSelect = "DAY 3";
  }
  if (round === "DAY 4") {
    roundSelect = "DAY 4";
  }
  if (round === "DAY 5") {
    roundSelect = "DAY 5";
  }

  if (round === "DAY 1") {
    roundSelector = "==";
  }
  if (round === "DAY 2") {
    roundSelector = "==";
  }
  if (round === "DAY 3") {
    roundSelector = "==";
  }
  if (round === "DAY 4") {
    roundSelector = "==";
  }
  if (round === "DAY 5") {
    roundSelector = "==";
  }

  useEffect(() => {
    (async () => {
      const listMatches = query(
        collection(db, "mlpmatches"),
        where("divisionID", "==", division),
        where("eventID", "==", location),
        where("live", "==", false),
        where("roundID", roundSelector, roundSelect)
      );

      const querySnapshot = await getDocs(listMatches);
      let matches = [];
      querySnapshot.forEach((doc) => {
        matches.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      setMatches(matches);
      /////////console.log(matches);
      matches.sort((a, b) => {
        if (a.date === b.date) {
          // /////////console.log(a.startTime.replace(":", ""));
          // /////////console.log(b.startTime.replace(":", ""));
          return Number(a.startTime.replace(":", "")) <
            Number(b.startTime.replace(":", ""))
            ? -1
            : 1;
        } else {
          return a.date < b.date ? -1 : 1;
        }
      });
    })();
  }, []);

  return (
    <div
      style={{
        paddingBottom: "5px",
        backgroundColor: "white",
      }}
    >
      <Row
        style={{
          alignContent: "center",
          justifyContent: "center",
          color: "white",
          backgroundColor: "#0a2137",
          height: "40px",
          paddingTop: "10px",
        }}
        className=" d-flex justify-content-md-center font-kanit"
      >
        <Col style={{ margin: "0" }}>
          <hr
            style={{
              height: "4px",
              color: "#ef612d",
            }}
          />
        </Col>
        <Col style={{ textAlign: "center", paddingBottom: "20px" }} xs={8}>
          {round}
        </Col>
        <Col style={{ margin: "0" }}>
          <hr
            style={{
              height: "4px",
              color: "#ef612d",
            }}
          />
        </Col>
      </Row>
      <div>
        {" "}
        <div>
          <div>
            {" "}
            {matches.map((match) => (
              <ScheduledMatchLayout match={match} live={false} timezone={timezone} />
            ))}{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
