import Image from "react-bootstrap/Image";
import { useEffect } from "react";
import challengerpic from "./images/bchallenger.jpg";
import premierpic from './images/bpremier.jpg'
// import premierpic from "./bpremier.jpg";

export const StatsBracket002 = (props) => {
  const { division } = props;
  /////////console.log(division)

  return window.innerWidth < 760 ? (
    <div style={{ overflow: "auto" }}>
      <div
        style={{
          paddingBottom: "30px",
          minWidth: "600px",
          maxWidth: "1200px",
        }}
      >
        {division === "Challenger" ? (
          <img
            width="500px"
            src={"https://www.liftkc.com/uploads/mlp2024.3.challenger.jpg"}
            // src={challengerpic}

            alt="challenger"
          />
        ) : (
          <img
            width="500px"
            src={"https://www.liftkc.com/uploads/mlp2024.3.premier.jpg"}
            // src={premierpic}

            alt="premier"
          />
        )}
      </div>
    </div>
  ) : (
    <div style={{ overflow: "auto" }}>
      <div
        style={{
          paddingBottom: "30px",
          minWidth: "600px",
          maxWidth: "1200px",
        }}
      >
        {division === "Challenger" ? (
          <img
          width="1150px"
          src={"https://www.liftkc.com/uploads/mlp2024.3.challenger.jpg"}
          // src={challengerpic}
          alt="challenger"
        />
        ) : (
          <img
            width="1150px"
            src={"https://www.liftkc.com/uploads/mlp2024.3.premier.jpg"}
            // src={premierpic}
            alt="premier"
          />
        )}
      </div>
    </div>
  );
};
