import Image from "react-bootstrap/Image";
import { useEffect } from "react";
import challengerpic from "./images/bchallenger.jpg";
import premierpic from "./images/bpremier.jpg";
// import premierpic from "./bpremier.jpg";

export const StatsBracket2024R1 = (props) => {
  const { division } = props;
  /////////console.log(division);

  return window.innerWidth < 760 ? (
    <div style={{ overflow: "auto" }}>
      <div
        style={{
          display: "flex",
          paddingBottom: "30px",
          minWidth: "200px",
          maxWidth: "500px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {division === "Challenger" ? (
          <img
            width="370px"
            src={"https://www.liftkc.com/uploads/mlp2024.r1.challenger.jpg"}
            // src={challengerpic}

            alt="challenger"
          />
        ) : (
          <img
            width="370px"
            src={"https://www.liftkc.com/uploads/mlp2024.r1.premier.jpg"}
            // src={premierpic}

            alt="premier"
          />
        )}
      </div>
    </div>
  ) : (
    <div style={{ overflow: "auto" }}>
      <div
        style={{
          display: "flex",
          paddingBottom: "30px",
          minWidth: "600px",
          maxWidth: "1200px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {division === "Challenger" ? (
          <img
            width="750px"
            src={"https://www.liftkc.com/uploads/mlp2024.r1.challenger2.jpg"}
            // src={challengerpic}
            alt="challenger"
          />
        ) : (
          <img
            width="750px"
            src={"https://www.liftkc.com/uploads/mlp2024.r1.premier2.jpg"}
            // src={premierpic}
            alt="premier"
          />
        )}
      </div>
    </div>
  );
};
