import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";

import { useState, useEffect } from "react";

import { Live } from "./Live";
import { AdminMatchLayout } from "./AdminMatchLayout";

import { db } from "../firebase-config";
import {
  doc,
  updateDoc,
  onSnapshot,
  arrayRemove,
  arrayUnion,
} from "firebase/firestore";

import { recalculateStandings } from "./standings.js";

export const GoLive = (props) => {
  const { matchID } = props;

  const [show, setShow] = useState(false);
  const [liveState, setLive] = useState(true);
  const [matchDetails, setMatchDetails] = useState([]);

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, "mlpmatches", matchID), (doc) => {
      setMatchDetails(doc.data());
    });
    return () => unsubscribe();
  }, []);

  const handleClose = () => {
    setShow(false);
    endMatch();
  };

  const handleButtonClick = (e) => {
    // /////////console.log({matchID});
    handleShow();
  };

  const handleShow = async () => {
    setShow(true);
    await updateDoc(doc(db, "mlpmatches", matchID), { live: true });
    await updateDoc(
      doc(db, "mlpLiveMatches" + matchDetails.divisionID, "live"),
      { matches: arrayUnion(matchID) }
    );
  };

  const endMatch = async () => {
    // /////////console.log("match ended");
    await updateDoc(doc(db, "mlpmatches", matchID), {
      live: false,
      status: "submitted",
    });
    await updateDoc(
      doc(db, "mlpLiveMatches" + matchDetails.divisionID, "live"),
      { matches: arrayRemove(matchID) }
    );

    await recalculateStandings(matchDetails.divisionID, matchDetails.eventID);
    
  };

  const endBroadcast = async () => {
    setShow(false);
    // /////////console.log("broadcast ended");
    setLive(false);
    await updateDoc(doc(db, "mlpmatches", matchID), {
      live: false,
      status: "paused",
    });
  };

  const setOrderW = async () => {
    await updateDoc(doc(db, "mlpmatches", matchID), {
      endTime: "W",
    });
  };

  const setOrderM = async () => {
    await updateDoc(doc(db, "mlpmatches", matchID), {
      endTime: "M",
    });
  };

  return (
    <>
      <Button size="sm" variant="success" onClick={handleShow}>
        Go Live
      </Button>

      <Modal show={show} onHide={handleButtonClick}>
        <Modal.Header>
          <Modal.Title>{liveState ? <Live /> : ""}</Modal.Title>
          <ButtonGroup aria-label="Basic example">
            
            <Button size="sm" variant="dark" onClick={endBroadcast}>
              Pause
            </Button>
          </ButtonGroup>
          <ButtonGroup aria-label="Basic example">
            <Button style={{borderRadius: "50px"}}  size="sm" variant="light" onClick={setOrderM}>
              Men's First
            </Button>
            <Button style={{borderRadius: "50px"}}  size="sm" variant="light" onClick={setOrderW}>
              Women's First
            </Button>
          </ButtonGroup>
          <ButtonGroup aria-label="Basic example">
            <Button size="sm" variant="primary" onClick={handleClose}>
              End Match
            </Button>
          </ButtonGroup>
        </Modal.Header>
        <Modal.Body>
          <AdminMatchLayout matchDetails={matchDetails} matchID={matchID} />
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            End Match
          </Button>
        </Modal.Footer> */}{" "}
      </Modal>
    </>
  );
};
