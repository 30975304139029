import { useState } from "react";
import { useEffect } from "react";
import { Live } from "./Live";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { LiveMatchLayout } from "./LiveMatchLayout";

import { db } from "../firebase-config";
import {
  collection,
  where,
  query,
  onSnapshot,
  getDocs,
} from "firebase/firestore";

export const LiveMatches = (props) => {
  const { day, division, location } = props;
  const dbname = "mlpLiveMatches" + division;
  const listMatches = collection(db, dbname);
  const [matches, setMatches] = useState([]);
/////////console.log(day);
  useEffect(() => {
    const queryMatches = query(listMatches);

    const unsubscribe = onSnapshot(queryMatches, (snapshot) => {
      let matches = [];
      snapshot.forEach((doc) => {
        matches.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      setMatches(matches);
    });
    return () => unsubscribe();
  }, []);

  return (
    <>
      <div
        style={{
          zoom: "100%",
          backgroundColor: "#0a2137",
        }}
        className=" justify-content-around"
      >
        {matches.length > 0 ? (
          <>
            <div>
              {matches[0].matches.map((match, index) =>
                index === 0 ? (
                  <Row
                    style={{
                      alignContent: "center",
                      justifyContent: "center",
                      color: "white",
                      backgroundColor: "#0a2137",
                      height: "40px",
                      paddingTop: "10px",
                    }}
                    className=" d-flex justify-content-md-center font-kanit"
                  >
                    <Col style={{ margin: "0" }}>
                      <hr
                        style={{
                          height: "4px",
                          color: "#ef612d",
                        }}
                      />
                    </Col>
                    <Col style={{ textAlign: "center" }} xs={8}>
                      LIVE MATCHES
                    </Col>
                    <Col style={{ margin: "0" }}>
                      <hr
                        style={{
                          height: "4px",
                          color: "#ef612d",
                        }}
                      />
                    </Col>
                  </Row>
                ) : (
                  <></>
                )
              )}
              {matches[0].matches.map((match) => (
                <>
                  <LiveMatchLayout
                    division={division}
                    match={match}
                    live={true}
                  />
                </>
              ))}{" "}
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
